<template>
  <div class="games">
    <h2>TrackIR Enhanced Games</h2>
    <input
      class="searchInput"
      type="text"
      v-model="searchString"
      placeholder="Search"
    />

    <div class="genreFilters">
      <h1>Filter By:</h1>
      <div class="capsuleContain">
        <a
          v-bind:class="{ active: fpsFilter }"
          v-on:click="fpsFilter = !fpsFilter"
          >FPS</a
        >
        <a
          v-bind:class="{ active: drivingFilter }"
          v-on:click="drivingFilter = !drivingFilter"
          >Driving</a
        >
        <a
          v-bind:class="{ active: flightFilter }"
          v-on:click="flightFilter = !flightFilter"
          >Flight</a
        >
        <a
          v-bind:class="{ active: spaceFilter }"
          v-on:click="spaceFilter = !spaceFilter"
          >Space</a
        >
      </div>
    </div>
    <div class="header">
      <div class="rowContain contentWrapper">
        <div class="gameArrow"></div>
        <div class="gameName">
          <a class="sortTrigger" v-on:click="sortTrigger('name')">
            Game Title
            <span
              v-bind:class="{
                'icon-down-open-big': sortKey === 'name',
                upArrow: sortAsc,
                downArrow: !sortAsc,
              }"
            ></span>
          </a>
        </div>
        <div class="gameReleased">
          <a class="sortTrigger" v-on:click="sortTrigger('released')">
            Released
            <span
              v-bind:class="{
                'icon-down-open-big': sortKey === 'released',
                upArrow: sortAsc,
                downArrow: !sortAsc,
              }"
            ></span>
          </a>
        </div>
      </div>
    </div>

    <transition-group id="gameList" name="gameList">
      <game-row
        :gameData="game"
        v-for="(game, index) in filteredAndSorted"
        :rowIndex="index"
        :nextGameId="findNextGame(index, filteredAndSorted)"
        :key="game.id"
      ></game-row>
    </transition-group>
  </div>
  <!-- END games -->
</template>
<script>
import { mapState } from 'vuex';
import GameRow from "@/components/GameRow";
import { SET_DISPLAYED_GAME } from "../store/mutation-types";

export default {
  name: "games",
  data() {
    return {
      searchString: "",
      sortKey: "released",
      sortAsc: false,
      fpsFilter: false,
      drivingFilter: false,
      flightFilter: false,
      spaceFilter: false,
      singleResultDisplayed: false,
    };
  },
  components: {
    GameRow,
  },
  computed: {
    ...mapState({
      games: (state) => state.game.games
    }),
    filteredAndSorted: function () {
      let result = JSON.parse(JSON.stringify(this.games));

      /* Filter out categories */
      if (
        this.fpsFilter ||
        this.drivingFilter ||
        this.flightFilter ||
        this.spaceFilter
      ) {
        result = result.filter((item) => {
          if (this.fpsFilter && item.category === "FIRST_PERSON") {
            return item;
          }
          if (this.drivingFilter && item.category === "RACING") {
            return item;
          }
          if (this.flightFilter && item.category === "FLIGHT") {
            return item;
          }
          if (this.spaceFilter && item.category === "SPACE") {
            return item;
          }
        });
      }

      /* Filter By Search */
      if (this.searchString) {
        result = result.filter((item) =>
          item.name.toLowerCase().includes(this.searchString.toLowerCase())
        );
      }

      let ascDesc = this.sortAsc ? 1 : -1;

      /* sort */
      if (this.sortKey === "trackedAxis") {
        result = result.sort(function (a, b) {
          let aCount = 0;
          let bCount = 0;
          if (a.yaw) {
            aCount++;
          }
          if (a.pitch) {
            aCount++;
          }
          if (a.roll) {
            aCount++;
          }
          if (a.x) {
            aCount++;
          }
          if (a.y) {
            aCount++;
          }
          if (a.z) {
            aCount++;
          }
          if (b.yaw) {
            bCount++;
          }
          if (b.pitch) {
            bCount++;
          }
          if (b.roll) {
            bCount++;
          }
          if (b.x) {
            bCount++;
          }
          if (b.y) {
            bCount++;
          }
          if (b.z) {
            bCount++;
          }
          if (aCount > bCount) {
            return 1;
          } else if (bCount > aCount) {
            return -1;
          } else {
            return 0;
          }
        });
      } else {
        result = result.sort((a, b) => { 

          if (!a[this.sortKey] && !b[this.sortKey]) {
            return 0;
          }
          if (!a[this.sortKey]) {
            return 1;
          }
          if (!b[this.sortKey]) {
            return -1;
          }

          return ascDesc * a[this.sortKey].localeCompare(b[this.sortKey]);

        });
      }
      if (this.sortKey === "trackedAxis" && !this.sortAsc) {
        result.reverse();
      }
      return result;
    },
  },
  watch: {
    //if only one result the game details will be displayed
    filteredAndSorted(newValue) {
      if (newValue.length == 1) {
        this.setDisplayedGame(newValue[0].id);
        this.singleResultDisplayed = true;
      } else {
        if (this.singleResultDisplayed) {
          this.setDisplayedGame(null);
          this.singleResultDisplayed = false;
        }
      }
    },
  },
  methods: {
    findNextGame: function (gameIndex, GamesArray) {
      let gameIndexNum = parseInt(gameIndex);
      let nextIndex = gameIndexNum + 1;

      if (nextIndex >= GamesArray.length) {
        return null;
      } else {
        return GamesArray[nextIndex].id.toString();
      }
    },

    sortTrigger: function (sortName) {
      if (this.sortKey === sortName) {
        if (this.sortAsc === true) {
          this.sortAsc = false;
        } else {
          this.sortAsc = true;
        }
      } else {
        this.sortKey = sortName;
        this.sortAsc = true;
      }
    },
    setDisplayedGame: function (rowIndex) {
      /* Closes game if currently open */
      if (rowIndex === this.displayedGame) {
        rowIndex = null;
      }
      this.$store.commit(SET_DISPLAYED_GAME, rowIndex);
    },
  },
  created() {
    //api call to get games data
    this.$store.dispatch("getVailableGames");
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.games {
  text-align: center;
  margin-bottom: 30px;

  .header {
    font-size: 16px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.1);

    .rowContain {
      .gameName,
      .gameAxis,
      .gameReleased {
        border-bottom: 0px;
      }

      div a {
        white-space: nowrap;
      }
    }
  }
  a.rowContain:hover {
    cursor: pointer;
  }
  .rowContain {
    text-align: left;
    display: flex;

    .sortTrigger {
      &:hover {
        cursor: pointer;
      }
    }

    &.noBorder {
      .gameName,
      .gameAxis,
      .gameReleased {
        border-bottom: 0px;
      }
    }

    .gameArrow {
      display: inline-block;
      width: 0px;
      line-height: 20px;
      border-bottom: 0px;
      font-size: 10px;
    }

    .gameName {
      width: 540px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
    .gameAxis {
      display: none;
      width: 120px;
      text-align: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);

      @include for-medium-up {
        display: inherit;
      }
    }
    .gameReleased {
      width: 120px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      padding-right: 0px;
      text-align: right;
    }

    div {
      padding: 10px 20px 10px 0;
    }
  }
}
.hide {
  display: none;
}

.searchInput {
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  border: 0px;
  height: 40px;
  width: 90%;
  max-width: 400px;
  margin-bottom: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #ffffff;
  outline: none;
}
::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.gameList {
  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-leave,
  &-enter-to {
    opacity: 1;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 0.3s ease-in-out;
  }
}

.genreFilters {
  margin-bottom: 40px;
  h1 {
    display: inline-block;
    font-size: 16px;
    text-transform: none;
    margin-right: 10px;
  }
  .capsuleContain {
    display: inline-block;
    font-size: 14px;
    a {
      transition: all 0.2s ease-out;
      padding: 5px 10px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);

      &.active {
        background-color: rgba(255, 255, 255, 0.5);
        color: #000000;
      }

      &:hover {
        background: var(--color-input-darken);
        cursor: pointer;
      }

      &:first-child {
        border-left: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 12px 0 0 12px;
      }

      &:last-child {
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 0 12px 12px 0;
      }
    }
  }
}
.upArrow,
.downArrow {
  display: inline-block;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.upArrow {
  animation-name: asc;
  transform: rotateX(180deg);
}
.downArrow {
  animation-name: desc;
}
</style>
