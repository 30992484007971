<template>
  <div class="gameRow">
    <div
      class="fullWidthRow"
      v-bind:class="{ darkRow: displayedGame === gameData.id }"
    >
      <a
        v-if="gameData.screenshot"
        @mouseover="preloadImage(gameData.screenshot)"
        @click.prevent="setDisplayedGame(gameData.id)"
        :class="{ noBorder: displayedGame === nextGameId }"
        class="rowContain contentWrapper"
      >
        <div
          class="gameArrow"
          v-bind:class="{
            upArrow: displayedGame === gameData.id,
            downArrow: displayedGame != gameData.id,
          }"
        >
          <span class="icon-down-open-big"></span>
        </div>
        <div class="gameName">{{ gameData.name }}</div>
        <div class="gameReleased">
          <span class="date">{{ formattedDate.date }} </span>
          <span class="month">{{ formattedDate.month }} </span>
          <span class="year">{{ formattedDate.year }}</span>
        </div>
      </a>
      <div v-else class="rowContain contentWrapper">
        <div class="gameArrow"></div>
        <div class="gameName">{{ gameData.name }}</div>
        <div class="gameReleased">
          <span class="date">{{ formattedDate.date }} </span>
          <span class="month">{{ formattedDate.month }} </span>
          <span class="year">{{ formattedDate.year }}</span>
        </div>
      </div>

      <transition-slide>
        <game-details
          v-if="displayedGame === gameData.id"
          :gameData="gameData"
        ></game-details>
      </transition-slide>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_DISPLAYED_GAME } from "../store/mutation-types";
import GameDetails from "@/components/GameDetails";
import TransitionSlide from "@/components/Transitions/TransitionSlide";
import { GAME_IMAGES_URL } from "@/constants/";
export default {
  name: "GameRow",
  props: ["gameData", "rowIndex", "nextGameId"],
  data() {
    return {
      detailsDisplayed: false,
      monthArray: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  computed: {
    ...mapState({
      displayedGame: (state) => state.game.displayedGame,
    }),
    formattedDate: function () {
      if (!this.gameData.released) {
        return { date: null, month: null, year: null };
      }
      let dateStr = this.gameData.released;

      if (typeof dateStr === "number") {
        /* checks if this is a number(timestamp) */
        var dateObj = new Date(dateStr);
        return {
          date: dateObj.getDate(),
          month: this.monthArray[dateObj.getMonth()],
          year: dateObj.getFullYear(),
        };
      } else if (dateStr.length === 8) {
        /* 8 character string YYYYMMDD */
        return {
          date: dateStr.substring(6, 8),
          month: this.monthArray[parseInt(dateStr.substring(4, 6)) - 1],
          year: dateStr.substring(0, 4),
        };
      } else if (dateStr.length === 6) {
        /* 6 character string YYYYMM */
        return {
          date: "01",
          month: this.monthArray[parseInt(dateStr.substring(4, 6)) - 1],
          year: dateStr.substring(0, 4),
        };
      } else if (dateStr.length === 4) {
        /* 4 character string YYYY */
        return { date: "01", month: "Jan", year: dateStr };
      } else {
        return { date: null, month: null, year: null };
      }
    },
  },
  components: {
    GameDetails,
    TransitionSlide,
  },
  methods: {
    setDisplayedGame: function (rowIndex) {
      /* Closes game if currently open */
      if (rowIndex === this.displayedGame) {
        rowIndex = null;
      }
      this.$store.commit(SET_DISPLAYED_GAME, rowIndex);
    },
    preloadImage: function (image) {
      if (image) {
        /* Create a image object */
        var preImg = new Image();

        /* Set the image source */
        preImg.src = GAME_IMAGES_URL + image;
      }
    },
    axisCount: function (gameData) {
      let count = 0;
      if (gameData.yaw) {
        count++;
      }
      if (gameData.pitch) {
        count++;
      }
      if (gameData.roll) {
        count++;
      }
      if (gameData.x) {
        count++;
      }
      if (gameData.y) {
        count++;
      }
      if (gameData.z) {
        count++;
      }
      return count;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.stayOnTarget {
  background-color: red;
  font-size: 48pt;
}
a.rowContain:hover {
  cursor: pointer;
}
.rowContain {
  text-align: left;
  display: flex;
  overflow-y: hidden;

  .sortTrigger {
    &:hover {
      cursor: pointer;
    }
  }

  &.noBorder {
    .gameName,
    .gameAxis,
    .gameReleased {
      border-bottom: 1px;
    }
  }

  .gameArrow {
    position: relative;
    display: inline-block;
    width: 0px;
    line-height: 20px;
    border-bottom: 0px;
    font-size: 10px;
  }

  .gameName {
    width: 540px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .gameAxis {
    display: none;
    width: 120px;
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    @include for-medium-up {
      display: block;
    }
  }
  .gameReleased {
    width: 120px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-right: 0px;
    text-align: right;

    .date,
    .month {
      display: none;
    }

    @include for-medium-up {
      .date,
      .month {
        display: inline;
      }
    }
  }

  div {
    padding: 10px 20px 10px 0;
  }
}
.fullWidthRow {
  transition: all 1s;
  background-color: transparent;
}
.darkRow {
  background-color: #161616;

  .rowContain {
    color: #ffffff;
    .gameName,
    .gameAxis,
    .gameReleased {
      border-bottom: 0px;
    }
  }
}
.upArrow span,
.downArrow span {
  display: inline-block;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.upArrow span {
  animation-name: asc;
  transform: rotateX(180deg);
}
.downArrow span {
  animation-name: desc;
}

@keyframes desc {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes asc {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}
</style>
