var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"games"},[_c('h2',[_vm._v("TrackIR Enhanced Games")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchString),expression:"searchString"}],staticClass:"searchInput",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.searchString)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchString=$event.target.value}}}),_c('div',{staticClass:"genreFilters"},[_c('h1',[_vm._v("Filter By:")]),_c('div',{staticClass:"capsuleContain"},[_c('a',{class:{ active: _vm.fpsFilter },on:{"click":function($event){_vm.fpsFilter = !_vm.fpsFilter}}},[_vm._v("FPS")]),_c('a',{class:{ active: _vm.drivingFilter },on:{"click":function($event){_vm.drivingFilter = !_vm.drivingFilter}}},[_vm._v("Driving")]),_c('a',{class:{ active: _vm.flightFilter },on:{"click":function($event){_vm.flightFilter = !_vm.flightFilter}}},[_vm._v("Flight")]),_c('a',{class:{ active: _vm.spaceFilter },on:{"click":function($event){_vm.spaceFilter = !_vm.spaceFilter}}},[_vm._v("Space")])])]),_c('div',{staticClass:"header"},[_c('div',{staticClass:"rowContain contentWrapper"},[_c('div',{staticClass:"gameArrow"}),_c('div',{staticClass:"gameName"},[_c('a',{staticClass:"sortTrigger",on:{"click":function($event){return _vm.sortTrigger('name')}}},[_vm._v("\n          Game Title\n          "),_c('span',{class:{
              'icon-down-open-big': _vm.sortKey === 'name',
              upArrow: _vm.sortAsc,
              downArrow: !_vm.sortAsc,
            }})])]),_c('div',{staticClass:"gameReleased"},[_c('a',{staticClass:"sortTrigger",on:{"click":function($event){return _vm.sortTrigger('released')}}},[_vm._v("\n          Released\n          "),_c('span',{class:{
              'icon-down-open-big': _vm.sortKey === 'released',
              upArrow: _vm.sortAsc,
              downArrow: !_vm.sortAsc,
            }})])])])]),_c('transition-group',{attrs:{"id":"gameList","name":"gameList"}},_vm._l((_vm.filteredAndSorted),function(game,index){return _c('game-row',{key:game.id,attrs:{"gameData":game,"rowIndex":index,"nextGameId":_vm.findNextGame(index, _vm.filteredAndSorted)}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }