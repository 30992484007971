<template>
  <div class="gameDetails contentWrapper">
    <div v-if="gameData.screenshot" class="imgWrapper">
      <img :src="imgPath" />
    </div>
    <div class="gameCopy" v-html="gameData.description"></div>
    <!-- END gameCopy -->
    <div class="btmRow">
      <div class="axisSupported">
        <h1>Tracked Axis</h1>
        <div v-if="gameData.yaw">Yaw</div>
        <div v-if="gameData.pitch">Pitch</div>
        <div v-if="gameData.roll">Roll</div>
        <div v-if="gameData.x">X</div>
        <div v-if="gameData.y">Y</div>
        <div v-if="gameData.z">Z</div>
      </div>
      <div v-if="gameData.url" class="btnWrapper">
        <a class="btn" :href="gameData.url" target="_blank">Visit Game Site</a>
      </div>
    </div>
  </div>
</template>
<script>
import { GAME_IMAGES_URL } from "@/constants/";
export default {
  name: "GameDetails",
  props: ["gameData"],
  computed: {
    imgPath: function () {
      return GAME_IMAGES_URL + this.gameData.screenshot;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.gameDetails {
  color: rgba(255, 255, 255, 0.5);
  //padding: 0px 20px 20px 65px;
  overflow: auto;
  box-sizing: border-box;

  .imgWrapper {
    width: 290px;
    float: none;
    margin: 5px auto 20px auto;
    text-align: center;

    @include for-medium-up {
      float: left;
      margin: 5px 20px 5px 20px;
    }
  }
  .gameCopy {
    padding: 0;
    text-align: left;

    @include for-medium-up {
      padding: 0 20px;
    }

    ::v-deep p {
      margin-top: 0px;
    }
    ::v-deep a {
      color: #ffffff;
      text-decoration: underline;
    }
    ::v-deep ol,
    ::v-deep ul {
      margin-top: 0px;
      display: inline-block;
      padding-left: 30px;
    }
  }
  .btmRow {
    clear: both;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 30px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    @include for-medium-up {
      flex-direction: row;
    }

    .axisSupported {
      width: 270px;

      @include for-medium-up {
        width: 780px;
      }
      h1 {
        font-size: 18px;
        margin: 0 0 10px;
        padding: 0px;
        text-transform: none;
      }
      div {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        display: inline-block;
        border: 1px solid #ffffff;
        color: #ffffff;
        width: 48px;
        height: 20px;
        margin-right: 10px;
        border-radius: 6px;
        margin-bottom: 10px;

        @include for-medium-up {
          margin-bottom: 0px;
        }
      }
    }
  }
  .btnWrapper {
    width: 100%;
    text-align: center;
    padding-top: 15px;
    margin-top: 10px;

    @include for-medium-up {
      text-align: right;
      margin-top: 0px;
    }
  }
  .btn {
    font-size: 14px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
</style>
